import * as React from "react";
import { CircularProgress } from "@mui/material";

export const LoaderContext = React.createContext<Function>(() => {});

export const LoaderProvider: React.FC = ({ children }) => {
  const [isLoading, renderLoader] = React.useState<boolean>(false);

  return (
    <LoaderContext.Provider value={renderLoader}>
      {isLoading ? <CircularProgress className="Center-Loader" color="secondary" /> : <></>}
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;

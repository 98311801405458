import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { getAuth, signInWithPopup, signOut, GoogleAuthProvider, UserCredential } from "firebase/auth";
import { AuthContext } from "../providers/AuthProvider";
import { SignInWithPopUpError } from "../types";
import UserService from "../services/userService";
import { AxiosError } from "axios";
import { SnackBarContext } from "../providers/SnackbarProvider";
import { Link, useHistory, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import UserAvatar from "./UserAvatar";

const MainAppBar: React.FC = () => {
  const user = React.useContext(AuthContext);
  const setSnackBarState = React.useContext(SnackBarContext);

  const location = useLocation();
  const history = useHistory();

  const onLogIn = () => {
    const auth = getAuth();
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result: UserCredential) => {
        result.user
          .getIdToken()
          .then((idToken) => {
            UserService.persistUser(idToken).catch((err: AxiosError) => {
              setSnackBarState({
                render: true,
                message: "Login Unsuccessful",
                severity: "error",
              });
              signOut(auth);
            });
          })
          .catch((err) => {
            setSnackBarState({
              render: true,
              message: "Login Unsuccessful",
              severity: "error",
            });
          });
      })
      .catch((_error: SignInWithPopUpError) => {
        // Can use data from error object for logging purposes
        setSnackBarState({
          render: true,
          message: "Login Unsuccessful",
          severity: "error",
        });
      });
  };

  const isOnFavoritePage = (): boolean => {
    const urlSplit = location.pathname.split("/");
    return urlSplit[urlSplit.length - 1] === "favorites";
  };

  const onLogOut = () => {
    if (isOnFavoritePage()) {
      history.push(`/`);
    }

    const auth = getAuth();
    signOut(auth).catch((error) => {
      setSnackBarState({
        render: true,
        message: "Logout Unsuccessful",
        severity: "error",
      });
      console.log(error);
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <div style={{ flexGrow: 1 }}>
            <ButtonBase onClick={() => history.push(`/`)}>
              <Typography variant="h6" component="div">
                Snowboard Bible
              </Typography>
            </ButtonBase>
          </div>
          {user && (
            <IconButton
              sx={{
                color: isOnFavoritePage() ? "#e12222" : "#e12222",
                paddingBottom: "9.1px",
              }}
              aria-label="favorite"
              component={Link}
              to="/trick_list/favorites"
            >
              <FavoriteIcon sx={{ height: "1.9rem", width: "27px" }} />
            </IconButton>
          )}
          {!user ? (
            <Button onClick={onLogIn} color="inherit">
              Login
            </Button>
          ) : (
            <UserAvatar onLogOut={onLogOut} />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MainAppBar;

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyATuLhYqUGWDDQeVAhpOomMpuXbrlFHvE0",
  authDomain: "snowboard-bible.firebaseapp.com",
  projectId: "snowboard-bible",
  storageBucket: "snowboard-bible.appspot.com",
  messagingSenderId: "24395385375",
  appId: "1:24395385375:web:797ad88827b6b23e7ea32a",
  measurementId: "G-S3MCZBRQGB",
};

const firebaseInit = () => initializeApp(firebaseConfig);

export default firebaseInit;

import { AxiosError, AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import TrickTabs from "../components/TrickTabs";
import TrickService from "../services/trickService";
import { Trick, TrickDetailsProps, TrickDetailsState, TrickStep } from "../types";
import { LoaderContext } from "../providers/LoaderProvider";
import React from "react";
import { useHistory } from "react-router-dom";
import { redirectToErrorPage } from "../utils";

const TrickDetails: React.FC<RouteComponentProps<TrickDetailsProps>> = ({ match }) => {
  const [trickDetailsState, setTrickDetailsState] = useState<TrickDetailsState>({ trickName: "", trickSteps: [] });

  const renderLoader = React.useContext(LoaderContext);
  const history = useHistory();

  const trickId: number = +match.params.trickId;

  useEffect(() => {
    const fetchDate = async () => {
      const updatedTrickDetailsState: TrickDetailsState = {
        trickName: "",
        trickSteps: [],
      };

      renderLoader(true);
      await TrickService.getTrick(trickId)
        .then((response: AxiosResponse<Trick>) => {
          updatedTrickDetailsState.trickName = response.data.name;
        })
        .catch((err: AxiosError) => {
          renderLoader(false);
          redirectToErrorPage(history);
        });

      await TrickService.getTrickSteps(trickId)
        .then((response: AxiosResponse<TrickStep[]>) => {
          updatedTrickDetailsState.trickSteps = response.data;
        })
        .catch((err: AxiosError) => {
          renderLoader(false);
          redirectToErrorPage(history);
        });

      setTrickDetailsState(updatedTrickDetailsState);

      renderLoader(false);
    };

    fetchDate();
  }, [trickId, renderLoader, history]);

  return (
    <>
      <TrickTabs trickName={trickDetailsState.trickName} trickSteps={trickDetailsState.trickSteps} />
    </>
  );
};

export default withRouter(TrickDetails);

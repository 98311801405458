import { History, Location } from "history";

export const redirectToErrorPage = (history: History<any>): void => {
  if (window.location.pathname === "/error") return;

  history.push(`/error?redirect=${window.location.pathname}`);
};

export const getUrlQueryParams = (location: Location, paramName: string): string | null => {
  return new URLSearchParams(location.search).get(paramName);
};

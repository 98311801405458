import { Trick, TrickListState, TrickObj, TrickStep } from "../types";
import axios, { AxiosError, AxiosResponse } from "axios";
import { createAxiosBearerHeader, getApiUrl } from "./serviceHelper";

const context = getApiUrl();

class TricksService {
  getAllTricks = (): Promise<AxiosResponse<TrickListState[]>> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context}/tricks/all`)
        .then((response: AxiosResponse<TrickListState[]>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  getTrick = (trickId: number): Promise<AxiosResponse<Trick>> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context}/tricks/trick/${trickId}`)
        .then((response: AxiosResponse<Trick>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  getTrickByType = (trickType: string, idToken?: string): Promise<AxiosResponse<TrickListState[]>> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context}/tricks/type/${trickType}`, idToken ? createAxiosBearerHeader(idToken) : undefined)
        .then((response: AxiosResponse<TrickListState[]>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  getTrickSteps = (trickId: number): Promise<AxiosResponse<TrickStep[]>> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context}/tricks/steps/${trickId}`)
        .then((response: AxiosResponse<TrickStep[]>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  getFavoritedTricksByUser = (idToken: string): Promise<AxiosResponse<TrickObj>> => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${context}/tricks/favorite`, createAxiosBearerHeader(idToken))
        .then((response: AxiosResponse<TrickObj>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  persistFavoritedTrick = (idToken: string, trickId: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${context}/tricks/favorite/${trickId}`, null, createAxiosBearerHeader(idToken))
        .then(() => {
          resolve();
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  deleteFavoritedTrick = (idToken: string, trickId: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${context}/tricks/favorite/${trickId}`, createAxiosBearerHeader(idToken))
        .then(() => {
          resolve();
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };
}

export default new TricksService();

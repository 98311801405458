import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import ErrorGrab from "../assets/errorGrab.svg";
import { getUrlQueryParams } from "../utils";

const Error: React.FC = () => {
  const location = useLocation();
  const redirectPath = getUrlQueryParams(location, "redirect");

  return (
    <Stack sx={{ marginLeft: "20%", marginRight: "20%", marginTop: "35%" }} direction="column">
      <img
        style={{ width: "247px", height: "147px", display: "block", margin: "auto auto 51% auto" }}
        src={ErrorGrab}
        alt="Error Grab"
      />
      <div style={{ marginBottom: "31%" }}>
        <Typography
          marginTop="12px"
          textAlign="center"
          alignContent="center"
          fontWeight="700"
          marginBottom="4%"
          variant="h5"
        >
          Error!
        </Typography>
        <Typography color="#8a8a8a" textAlign="center" alignContent="center" variant="subtitle1">
          Something went wrong
        </Typography>
        <Typography color="#8a8a8a" textAlign="center" alignContent="center" variant="subtitle1">
          Please try again
        </Typography>
      </div>
      <Button
        sx={{ alignSelf: "center", width: "66%" }}
        variant="outlined"
        size="large"
        component={Link}
        to={redirectPath ? redirectPath : "/"}
      >
        Try again
      </Button>
    </Stack>
  );
};

export default Error;

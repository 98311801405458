import React from "react";
import { Redirect, Route } from "react-router";
import { AuthContext } from "./providers/AuthProvider";

const PrivateRoute: React.FC<{
  path: string;
  component: React.ComponentType<any>;
}> = ({ path, component }) => {
  const user = React.useContext(AuthContext);

  return user ? <Route exact path={path} component={component} /> : <Redirect to="/" />;
};

export default PrivateRoute;

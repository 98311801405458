import styled from "@emotion/styled";
import React from "react";
import CardButton from "../components/CardButton";
import basicsImage from "../assets/basic.svg";
import spinsImage from "../assets/spin.svg";
import buttersImage from "../assets/butter.svg";
import grabsImage from "../assets/grab.svg";
import FeedbackDialog from "../components/FeedbackDialog";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 8%;
`;

const MainMenu: React.FC = () => {
  return (
    <div>
      <FlexContainer>
        <CardButton title="Basics" route="/trick_list/basic" image={basicsImage} />
        <CardButton title="Spins" route="/trick_list/spin" image={spinsImage} />
      </FlexContainer>
      <FlexContainer>
        <CardButton title="Butters" route="/trick_list/butter" image={buttersImage} isDisabled={true} />
        <CardButton title="Grabs" route="/trick_list/grab" image={grabsImage} isDisabled={true} />
      </FlexContainer>
      <div style={{ position: "fixed", bottom: "18px", right: "14px" }}>
        <FeedbackDialog />
      </div>
    </div>
  );
};

export default MainMenu;

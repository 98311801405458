import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { TabPanelProps, TrickStep, TrickTabsProps } from "../types";

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TrickTabs: React.FC<TrickTabsProps> = (props: TrickTabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabs = props.trickSteps.map((element: TrickStep, index: number) => {
    return <Tab key={element.step_number} label={"Step #" + (index + 1)} {...a11yProps(index)} />;
  });

  const tabPanels = props.trickSteps.map((element: TrickStep, index: number) => {
    return (
      <TabPanel key={element.step_number} value={value} index={index}>
        {element.description}
      </TabPanel>
    );
  });

  const trickVids = props.trickSteps.map((element: TrickStep, index: number) => {
    const queryParams = "&autoplay=1&loop=1&muted=1&byline=0&color=ffffff";
    return (
      <div key={element.step_number} hidden={value !== index}>
        <div
          style={{
            padding: "56.25% 0 0 0",
            position: "relative",
          }}
        >
          <iframe
            src={element.url + queryParams}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title={`${element.trick_id}-${element.step_number}`}
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    );
  });

  return (
    <Box sx={{ width: "100%" }}>
      {trickVids}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          backgroundColor: "#dbdbdb",
        }}
      >
        {tabs.length > 1 && (
          <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
            {tabs}
          </Tabs>
        )}
      </Box>

      {tabPanels}
    </Box>
  );
};

export default TrickTabs;

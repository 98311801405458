import React, { useState, useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { Divider, List } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import TrickService from "../services/trickService";
import { TrickListProps, TrickListState, TrickObj } from "../types";
import { AxiosError, AxiosResponse } from "axios";
import { AuthContext } from "../providers/AuthProvider";
import { TransitionGroup } from "react-transition-group";
import TrickListCard from "../components/TrickListCard";
import { LoaderContext } from "../providers/LoaderProvider";
import { redirectToErrorPage } from "../utils";
import { SnackBarContext } from "../providers/SnackbarProvider";

const TrickList: React.FC<RouteComponentProps<TrickListProps>> = () => {
  const [trickObj, setTrickObj] = useState<TrickObj>({
    basic: [],
    grab: [],
    spin: [],
    butter: [],
  });

  const user = React.useContext(AuthContext);
  const renderLoader = React.useContext(LoaderContext);
  const setSnackBarState = React.useContext(SnackBarContext);

  const history = useHistory();

  useEffect(() => {
    if (!user) return;
    renderLoader(true);

    user
      .getIdToken()
      .then((idToken) => {
        TrickService.getFavoritedTricksByUser(idToken)
          .then((response: AxiosResponse<TrickObj>) => {
            setTrickObj(response.data);
            renderLoader(false);
          })
          .catch((err: AxiosError) => {
            renderLoader(false);
            redirectToErrorPage(history);
          });
      })
      .catch((err) => {
        console.log(err);
        renderLoader(false);
        redirectToErrorPage(history);
      });
  }, [user, renderLoader, history]);

  const handleRemoveIcon = (selectedTrickListItem: TrickListState) => () => {
    if (!user) return;

    user
      .getIdToken()
      .then((idToken) => {
        TrickService.deleteFavoritedTrick(idToken, selectedTrickListItem.id)
          .then(() => {
            const filtered = trickObj[selectedTrickListItem.type!].filter(
              (trick: any) => trick.id !== selectedTrickListItem.id
            );
            trickObj[selectedTrickListItem.type!] = filtered;
            setTrickObj({ ...trickObj });
          })
          .catch((err) => {
            console.log(err);
            setSnackBarState({
              render: true,
              message: "Favoriting Error",
              severity: "error",
            });
          });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarState({
          render: true,
          message: "Favoriting Error",
          severity: "error",
        });
      });
  };

  const basicTrickList = trickObj?.basic
    ? trickObj.basic.map((element: TrickListState) => {
        const linkDetails = "/trick_list/trick_details/" + element.id;
        return (
          <Collapse key={element.id}>
            <TrickListCard
              trickListItem={element}
              linkDetails={linkDetails}
              handleIcon={handleRemoveIcon}
              isRemovable={true}
            />
          </Collapse>
        );
      })
    : [];

  const butterTrickList = trickObj?.butter
    ? trickObj.butter.map((element: TrickListState) => {
        const linkDetails = "/trick_list/trick_details/" + element.id;
        return (
          <Collapse key={element.id}>
            <TrickListCard
              trickListItem={element}
              linkDetails={linkDetails}
              handleIcon={handleRemoveIcon}
              isRemovable={true}
            />
          </Collapse>
        );
      })
    : [];

  const grabTrickList = trickObj?.grab
    ? trickObj.grab.map((element: TrickListState) => {
        const linkDetails = "/trick_list/trick_details/" + element.id;
        return (
          <Collapse key={element.id}>
            <TrickListCard
              trickListItem={element}
              linkDetails={linkDetails}
              handleIcon={handleRemoveIcon}
              isRemovable={true}
            />
          </Collapse>
        );
      })
    : [];

  const spinTrickList = trickObj?.spin
    ? trickObj.spin.map((element: TrickListState) => {
        const linkDetails = "/trick_list/trick_details/" + element.id;
        return (
          <Collapse key={element.id}>
            <TrickListCard
              trickListItem={element}
              linkDetails={linkDetails}
              handleIcon={handleRemoveIcon}
              isRemovable={true}
            />
          </Collapse>
        );
      })
    : [];

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      {basicTrickList.length > 0 ||
      butterTrickList.length > 0 ||
      grabTrickList.length > 0 ||
      spinTrickList.length > 0 ? (
        <List sx={{ paddingTop: "0px" }}>
          <TransitionGroup>
            {basicTrickList.length > 0 ? (
              <>
                <ListSubheader>{`Basics`}</ListSubheader>
                <Divider />
              </>
            ) : (
              <></>
            )}
            {basicTrickList}
            {butterTrickList.length > 0 ? (
              <>
                <ListSubheader>{`Butters`}</ListSubheader>
                <Divider />
              </>
            ) : (
              <></>
            )}
            {butterTrickList}
            {grabTrickList.length > 0 ? (
              <>
                <ListSubheader>{`Grabs`}</ListSubheader>
                <Divider />
              </>
            ) : (
              <></>
            )}
            {grabTrickList}
            {spinTrickList.length > 0 ? (
              <>
                <ListSubheader>{`Spins`}</ListSubheader>
                <Divider />
              </>
            ) : (
              <></>
            )}
            {spinTrickList}
          </TransitionGroup>
        </List>
      ) : (
        <h4 style={{ textAlign: "center" }}>No Favorited Tricks</h4>
      )}
    </Box>
  );
};

export default TrickList;

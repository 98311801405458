import * as React from "react";
import styled from "@emotion/styled";
import { SnackBarContext } from "../providers/SnackbarProvider";
import { MdFavorite, MdOutlineShare, MdDelete } from "react-icons/md";
import { TrickListCardProps } from "../types";
import { Link } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";

const CardWrapper = styled.div<{ isDisabled: boolean }>`
  height: 77px;
  width: 92%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin: 10px auto;
  opacity: ${(props) => (props.isDisabled ? "0.4" : "initial")};
`;

const CardContent = styled.div`
  display: flex;
`;

const TrickImage = styled.div<{ primary: string }>`
  background-image: url(${(props) => props.primary});
  background-position: center;
  display: block;
  height: 78px;
  width: 143px;
  background-size: cover;
`;
const Title = styled.div`
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  margin-left: 16px;
  margin-top: 4px;
  margin-bottom: 8px;
`;

const Divider = styled.hr`
  margin: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  margin-left: 15px;
  margin-right: 15px;
`;

const TextContent = styled.div`
    display: flex
    flex-direction: column;
    flex-grow: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Button = styled.button`
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  min-width: 64px;
  padding: 4px 5px;
  border-radius: 4px;
  color: black;
  text-transform: none;
  display: flex;
`;

const TrickListCard: React.FC<TrickListCardProps> = ({ trickListItem, linkDetails, handleIcon, isRemovable }) => {
  const setSnackBarState = React.useContext(SnackBarContext);
  const user = React.useContext(AuthContext);

  const thumbNailImage = trickListItem.thumb_nail ?? "Gliding.png";
  return (
    <Link
      style={{ textDecoration: "none", pointerEvents: !trickListItem.active ? "none" : "initial" }}
      to={linkDetails}
    >
      <CardWrapper isDisabled={!trickListItem.active}>
        <CardContent>
          <TrickImage primary={require(`../assets/${thumbNailImage}`).default} />
          <TextContent>
            <Title>{trickListItem.name}</Title>
            <Divider />
            <ButtonContainer>
              <Button
                onMouseDown={(event) => event.stopPropagation()}
                onTouchStart={(event) => event.stopPropagation()}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  if (!user) {
                    setSnackBarState({
                      render: true,
                      message: "Login to Favorite Tricks",
                      severity: "info",
                    });
                  }
                  handleIcon(trickListItem)();
                }}
              >
                {!isRemovable ? (
                  <div>
                    <MdFavorite
                      style={{
                        height: "18px",
                        width: "21px",
                        marginBottom: "3px",
                        marginRight: "2px",
                        color: trickListItem.isFavorited ? "#e12222" : "rgba(0, 0, 0, 0.26)",

                        verticalAlign: "middle",
                      }}
                    />
                    {"Favorite"}
                  </div>
                ) : (
                  <div>
                    <MdDelete
                      style={{
                        height: "18px",
                        width: "21px",
                        marginBottom: "5px",
                        marginRight: "2px",
                        verticalAlign: "middle",
                        color: "#1976d2",
                      }}
                    />
                    {"Remove"}
                  </div>
                )}
              </Button>
              <Button
                onMouseDown={(event) => event.stopPropagation()}
                onTouchStart={(event) => event.stopPropagation()}
                onClick={async (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  try {
                    await navigator.clipboard.writeText(window.location.origin + linkDetails);
                    setSnackBarState({
                      render: true,
                      message: "Copied to Clipboard!",
                      severity: "success",
                    });
                  } catch (e) {
                    setSnackBarState({
                      render: true,
                      message: "Copy Error",
                      severity: "error",
                    });
                  }
                }}
              >
                <MdOutlineShare
                  style={{
                    height: "18px",
                    width: "21px",
                    marginBottom: "2px",
                    marginRight: "2px",
                  }}
                />
                Share
              </Button>
            </ButtonContainer>
          </TextContent>
        </CardContent>
      </CardWrapper>
    </Link>
  );
};

export default TrickListCard;

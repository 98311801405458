import * as React from "react";
import { Alert } from "@mui/material";
import { Snackbar } from "@mui/material";
import { SnackBarState } from "../types";

export const SnackBarContext = React.createContext<React.Dispatch<React.SetStateAction<SnackBarState>>>(() => {});

export const SnackBarProvider: React.FC = ({ children }) => {
  const [snackBarState, setSnackBarState] = React.useState<SnackBarState>({
    render: false,
    message: "",
    severity: undefined,
  });

  const setDefaultSnackBarState = () => {
    // Need to reuse the severity value becuase it will cause component to default to 'success' if severity is set to undefined
    // This causes small UI glitch that will flash the 'success' snack bar for a brief second
    setSnackBarState({
      render: false,
      message: "",
      severity: snackBarState.severity,
    });
  };

  return (
    <SnackBarContext.Provider value={setSnackBarState}>
      {children}
      <Snackbar open={snackBarState.render} autoHideDuration={6000} onClose={setDefaultSnackBarState}>
        <Alert onClose={setDefaultSnackBarState} severity={snackBarState.severity} sx={{ width: "100%" }}>
          {snackBarState.message}
        </Alert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;

import styled from "@emotion/styled";
import * as React from "react";
import cardBackgroundImage from "../assets/cardBackground.svg";

import { CardButtonProps } from "../types";
import { Link } from "react-router-dom";

const CardWrapper = styled.div<{ isDisabled: boolean }>`
  height: 282px;
  width: 164px;
  border-radius: 13px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  opacity: ${(props) => (props.isDisabled ? "0.4" : "initial")};
`;

const Image = styled.div`
  background-image: url(${cardBackgroundImage});
  height: 240px;
  width: 164px;
`;

const ImageGrab = styled.div<{ primary: string }>`
  background-image: url(${(props) => props.primary});
  height: 242px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  object-fit: cover;
`;

const Title = styled.div`
  margin: 0px 0px 0.35em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
  margin-left: 13px;
`;

const CardButton: React.FC<CardButtonProps> = (props: CardButtonProps) => {
  return (
    <Link
      style={{ textDecoration: "none", color: "black", pointerEvents: props.isDisabled ? "none" : "initial" }}
      to={props.route}
    >
      <CardWrapper isDisabled={props.isDisabled ?? false}>
        <Image>
          <ImageGrab primary={props.image} />
        </Image>
        <Title>{props.title}</Title>
      </CardWrapper>
    </Link>
  );
};

export default CardButton;

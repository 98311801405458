import React, { useState, useEffect } from "react";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { List } from "@mui/material";
import TrickService from "../services/trickService";
import { TrickListProps, TrickListState } from "../types";
import { AxiosError, AxiosResponse } from "axios";
import { AuthContext } from "../providers/AuthProvider";
import { LoaderContext } from "../providers/LoaderProvider";
import { redirectToErrorPage } from "../utils";
import { SnackBarContext } from "../providers/SnackbarProvider";
import TrickListCard from "../components/TrickListCard";

const TrickList: React.FC<RouteComponentProps<TrickListProps>> = ({ match }) => {
  const [trickList, setTrickList] = useState<TrickListState[]>([]);
  const history = useHistory();

  const trickType = match.params.trickType;

  const user = React.useContext(AuthContext);
  const renderLoader = React.useContext(LoaderContext);
  const setSnackBarState = React.useContext(SnackBarContext);

  useEffect(() => {
    renderLoader(true);

    const fetchTrickByType = async () => {
      const idToken: string | undefined = user
        ? await user.getIdToken().catch((err) => {
            renderLoader(false);
            redirectToErrorPage(history);
            return undefined;
          })
        : undefined;

      TrickService.getTrickByType(trickType, idToken)
        .then((response: AxiosResponse<TrickListState[]>) => {
          setTrickList(response.data);
          renderLoader(false);
        })
        .catch((err: AxiosError) => {
          renderLoader(false);
          redirectToErrorPage(history);
        });
    };

    fetchTrickByType();
  }, [trickType, user, renderLoader, history]);

  const changeIsFavoritedValue = (selectedTrickId: number, isFavorited: boolean): void => {
    trickList.forEach((trick) => {
      if (trick.id === selectedTrickId) {
        trick.isFavorited = isFavorited;
      }
    });

    setTrickList([...trickList]);
  };

  const handleFavoriteIcon = (selectedTrickListItem: TrickListState) => () => {
    if (!user) return;

    user
      .getIdToken()
      .then((idToken: string) => {
        if (!selectedTrickListItem.isFavorited) {
          // if authenticated and trick is NOT favorited

          TrickService.persistFavoritedTrick(idToken, selectedTrickListItem.id)
            .then(() => {
              changeIsFavoritedValue(selectedTrickListItem.id, true);
            })
            .catch((err) => {
              console.log(err);
              setSnackBarState({
                render: true,
                message: "Favoriting Error",
                severity: "error",
              });
            });
        } else {
          TrickService.deleteFavoritedTrick(idToken, selectedTrickListItem.id)
            .then(() => {
              changeIsFavoritedValue(selectedTrickListItem.id, false);
            })
            .catch((err) => {
              console.log(err);
              setSnackBarState({
                render: true,
                message: "Favoriting Error",
                severity: "error",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackBarState({
          render: true,
          message: "Favoriting Error",
          severity: "error",
        });
      });
  };

  const trickListCards = trickList.map((element: TrickListState) => {
    const linkDetails = "/trick_list/trick_details/" + element.id;
    return (
      <div key={element.id}>
        <TrickListCard trickListItem={element} linkDetails={linkDetails} handleIcon={handleFavoriteIcon} />
      </div>
    );
  });

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <List>{trickListCards}</List>
    </Box>
  );
};

export default withRouter(TrickList);

import * as React from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useEffect } from "react";

export const AuthContext = React.createContext<User | null>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, setUser);
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

import axios, { AxiosError, AxiosResponse } from "axios";
import { createAxiosBearerHeader, getApiUrl } from "./serviceHelper";

const context = getApiUrl();

class UserService {
  persistUser = (idToken: string): Promise<AxiosResponse<string>> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${context}/persistUser`, null, createAxiosBearerHeader(idToken))
        .then((response: AxiosResponse<string>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  postFeedback = (feedback: string, rating: number, idToken?: string): Promise<AxiosResponse<string>> => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${context}/feedback`,
          { feedback, rating, idToken },
          idToken ? createAxiosBearerHeader(idToken) : undefined
        )
        .then((response: AxiosResponse<string>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };

  persistFingerPrint = (fingerPrintId: string): Promise<AxiosResponse<string>> => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${context}/persistFingerPrint/${fingerPrintId}`)
        .then((response: AxiosResponse<string>) => {
          resolve(response);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  };
}

export default new UserService();

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainAppBar from "./components/MainAppBar";
import MainMenu from "./pages/MainMenu";
import TrickList from "./pages/TrickList";
import TrickDetails from "./pages/TrickDetails";
import FavoriteTrickList from "./pages/FavoriteTrickList";
import Error from "./pages/Error";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "./providers/AuthProvider";
import LoaderProvider from "./providers/LoaderProvider";
import SnackbarProvider from "./providers/SnackbarProvider";
import "./App.css";
import React, { useEffect } from "react";
import UserService from "./services/userService";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

const App: React.FC = () => {
  const { getData } = useVisitorData();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    getData().then((data) => {
      UserService.persistFingerPrint(data.visitorId);
    });
  }, []);

  return (
    <AuthProvider>
      <LoaderProvider>
        <SnackbarProvider>
          <Router>
            <header>
              <MainAppBar />
            </header>
            <Switch>
              <Route exact path="/trick_list/trick_details/:trickId" component={TrickDetails} />
              <PrivateRoute path="/trick_list/favorites" component={FavoriteTrickList} />
              <Route exact path="/trick_list/:trickType" component={TrickList} />
              <Route exact path="/error" component={Error} />
              <Route path="/" component={MainMenu} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </LoaderProvider>
    </AuthProvider>
  );
};

export default App;

import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Rating from "@mui/material/Rating";
import UserService from "../services/userService";
import { AuthContext } from "../providers/AuthProvider";
import { SnackBarContext } from "../providers/SnackbarProvider";

const FeedbackDialog: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const [feedback, setFeedback] = React.useState("");
  const [disableSubmit, setDisableSubmit] = React.useState(true);

  const user = React.useContext(AuthContext);
  const setSnackBarState = React.useContext(SnackBarContext); // FIGURE OUT HOW TO GET JUST THE SET FUNCTION

  const handleClickOpen = () => {
    setOpen(true);
    resetFields();
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const idToken: string | undefined = user
      ? await user.getIdToken().catch((err) => {
          setSnackBarState({
            render: true,
            message: "Feedback Error",
            severity: "error",
          });
          return undefined;
        })
      : undefined;

    UserService.postFeedback(feedback, rating, idToken)
      .then(() => {
        setOpen(false);
        setSnackBarState({
          render: true,
          message: "Feedback Sent",
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setSnackBarState({
          render: true,
          message: "Feedback Error",
          severity: "error",
        });
      });
  };

  const resetFields = () => {
    setRating(0);
    setFeedback("");
    setDisableSubmit(true);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Feedback
      </Button>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please send me all of your feedback. What do you like about the app? What do you hate? I want this app to
            grow so we can help you improve.
          </DialogContentText>
          <Rating
            sx={{ paddingTop: "8px" }}
            onChange={(event, newValue) => {
              setDisableSubmit(newValue! >= 1 ? false : true);
              setRating(newValue!);
            }}
            value={rating}
            name="size-large"
            size="large"
          />
          <TextField
            autoFocus
            margin="dense"
            id="feedback"
            label="Feedback"
            type="text"
            fullWidth
            variant="standard"
            multiline
            value={feedback}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFeedback(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={disableSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeedbackDialog;
